@import "@fontsource/noto-sans/latin-400.css";
@import "@fontsource/noto-sans/latin-700.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .legal-page {
    font-family: 'Noto Sans', sans-serif;
  }
}